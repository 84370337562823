import React from "react"
import { StaticQuery, graphql } from "gatsby"

const DataProvider =
  ConnectedComponent =>
  ({ pathContext: enhancedProps }) => {
    const findRelatedCampaigns = ({
      allCampaignSets: { edges = [] },
      allCampaign: { nodes: campaignNodes = [] },
    }) => {
      const campaignSetIds = edges.map(edge =>
        edge.node.acf.campaign_variants.map(
          rootCampaign => rootCampaign.alternative_id
        )
      )

      const [normalizedIdArrays = []] = campaignSetIds.filter(idSet =>
        idSet.includes(enhancedProps.alternative_id.toString())
      )
      const relatedCampaigns = normalizedIdArrays.map(relatedId =>
        campaignNodes.find(node => node.alternative_id == relatedId)
      )
      return relatedCampaigns
    }

    const metaTags = {
      language: enhancedProps.acf.language,
      title: enhancedProps.acf.meta_title,
      shortDescription: enhancedProps.acf.meta_short_description,
      longDescription: enhancedProps.acf.meta_long_description,
      keywords: enhancedProps.acf.meta_keywords,
      twitterAuthor: enhancedProps.acf.twitter_handle,
      previewPicture: enhancedProps.acf.meta_preview_pics,
      favicon: enhancedProps.acf.favicon,
      url: `/${enhancedProps.slug}`,
    }
    return (
      <StaticQuery
        query={rootCampaignData}
        render={campaignSets => (
          <ConnectedComponent
            {...enhancedProps}
            metaTags={metaTags}
            campaignSets={findRelatedCampaigns(campaignSets)}
          />
        )}
      />
    )
  }

export default DataProvider

const rootCampaignData = graphql`
  query campaignSets {
    allCampaignSets {
      edges {
        node {
          acf {
            campaign_variants {
              alternative_id
            }
          }
        }
      }
    }
    allCampaign(filter: { slug: { ne: null } }) {
      nodes {
        slug
        alternative_id
        acf {
          archive
          language
          no_prefill
          number_of_line
        }
      }
    }
  }
`
