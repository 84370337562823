/* eslint-disable no-underscore-dangle */
export const PROCA_HTML_CONTAINER_ID = "proca-listener"
export const PROCE_HTML_FORM_ID = "proca-form"
export const initProcaWidgetWithRetry = (showProca, language) => {
  const _retry = () => {
    if (
      window.proca &&
      window.proca.go &&
      document.getElementById(PROCA_HTML_CONTAINER_ID)
    ) {
      if (!showProca) {
        const defaultContainer = document.getElementById("proca-widget")
        const procaFab = document.getElementById("proca-fab")
        if (defaultContainer) {
          defaultContainer.style.display = "none"
        }
        if (procaFab) {
          procaFab.style.display = "none"
        }
      }
      return window.proca.go(1)
    }

    const script = document.createElement("script")
    script.src = `https://widget.proca.app/d/campax/collect/${language}?v=pandemicv2`
    script.id = "proca"
    script.async = true
    document.body.appendChild(script)

    return setTimeout(() => {
      _retry()
    }, 500)
  }

  _retry()
}
