import React, { useEffect, useLayoutEffect, useState } from "react"
import PropTypes from "prop-types"
import he from "he"
import CookieConsent from "react-cookie-consent"

import {
  LanguageBarComponent,
  HeadComponent,
  Button,
} from "../../components/components"

import dataProvider from "./_data"
import { PROCE_HTML_FORM_ID, initProcaWidgetWithRetry } from "./_proca.config"

import styles from "./_styles.module.scss"

const procaUrlPickup = (
  <div className="proca proca-html" style={{ display: "none" }}>
    <h2>{`Thank you {firstName}!`}</h2> <p> your document is now ready:</p>
    <a href="{postcardUrl}" target="_blank" className="btn button">
      PDF to print and sign
    </a>
  </div>
)

const MobileLayout = ({ content, showWidget, widget }) => (
  <div className={styles.mobileLayout}>
    <div className={styles.campaignBodyMobile}>
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </div>
    {showWidget && widget}
    {procaUrlPickup}
  </div>
)

const DesktopLayout = ({ content, showWidget, widget, copy }) => (
  <div className={styles.desktopLayout}>
    <div
      className={[styles.campaignActions, !showWidget && styles.removeBck].join(
        " "
      )}
    >
      {showWidget ? (
        <div
          style={{ display: [showWidget].every(Boolean) ? "block" : "none" }}
        >
          {widget}
        </div>
      ) : (
        <Button label={copy.actionButtonArchived} disabled type="template" />
      )}
    </div>
    <div className={styles.campaignBody}>
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </div>
    {procaUrlPickup}
  </div>
)

const RawPage = ({
  metaTags,
  alternative_id: alternativeId,
  acf,
  content,
  campaignSets = [],
}) => {
  const showWidget = [!acf.archive].every(Boolean)
  const [isMobile, setMobile] = useState(true)
  const {
    campaignTemplate: copy,
    cookies,
  } = require(`../../locales/${acf.language}.json`)

  useEffect(() => {
    if (window) {
      return setMobile(window.innerWidth < 769)
    }
    return null
  }, [])

  useLayoutEffect(() => {
    initProcaWidgetWithRetry(showWidget, acf.language)
  }, [showWidget])

  const widget = (
    <div
      className={styles.widgetContainer}
      id={PROCE_HTML_FORM_ID}
      data-twitter-text={acf.twitter_default_message}
      data-campaign-id={alternativeId}
      data-pdf-url={acf.pdf_cover_image}
      data-margin-top={acf.pdf_margin_top}
      data-prefill={acf.no_prefill}
      data-lines={acf.number_of_line}
    />
  )

  const layoutProps = {
    content: content.rendered,
    widget,
    showWidget,
  }

  const cookieConfiguration = {
    location: "top",
    buttonText: cookies.acceptButton,
    cookieName: "allow-cookies",
    style: { background: "#2B373B" },
    buttonStyle: { color: "#4e503b", fontSize: "13px" },
    expires: 150,
  }

  return (
    <>
      <CookieConsent {...cookieConfiguration}>
        {cookies.body}
        <a href={cookies.bodyMoreInfoUrl}>{cookies.bodyMoreInfoUrl}</a>
      </CookieConsent>
      <HeadComponent {...metaTags} />(
      <LanguageBarComponent
        sets={campaignSets}
        currentLanguage={acf.language}
      />
      {!isMobile ? (
        <DesktopLayout {...layoutProps} copy={copy} />
      ) : (
        <MobileLayout {...layoutProps} />
      )}
    </>
  )
}

RawPage.propTypes = {
  metaTags: PropTypes.object.isRequired,
  alternative_id: PropTypes.string.isRequired,
  acf: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
  campaignSets: PropTypes.array,
}

RawPage.defaultProps = {
  campaignSets: [],
}

export default dataProvider(RawPage)
